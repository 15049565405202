import styles from "./Projects.module.css";
import TitlePage from "components/TitlePage";
import Project from "components/Project";

export default function Projects() {
    return (
        <main className={styles.container}>
            <TitlePage title="Projetos" />

            <Project />
        </main>
    )
}