import { Link } from "react-router-dom";
import styles from "./Project.module.css";
import Image from 'react-image-webp';

export default function Projects() {
    return (
        <>
            <section className={styles.contentLeft}>
                <div className={styles.left}>
                    <h2>Projeto ProImprensa</h2>

                    <div className={styles.backgroundText}>
                        <p>O ProImprensa é um projeto corporativo focado em comunicação, marketing e mídia digital, desenvolvido com PHP, MySQL, CSS3 e Bootstrap 4, garantindo funcionalidade, responsividade e design moderno. Ele é organizado em seções como "Quem Somos", "Serviços", "Clientes", "Cases" e "Contato", além de incluir um blog corporativo.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>O site possui um painel de administração que permite gerenciar conteúdos como postagens, clientes, cases e contatos recebidos via formulário, facilitando a atualização sem necessidade de conhecimentos técnicos. O uso de MySQL assegura um armazenamento eficiente de dados.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>Com uma interface responsiva e experiência de usuário aprimorada, o ProImprensa é ideal para empresas que desejam promover seus serviços de forma profissional. Possíveis melhorias incluem otimização SEO, integração com Analytics. É uma solução sólida e atrativa para o mercado digital.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>
                            <Link className={styles.link} to="https://www.proimprensa.com.br" target="_blank">Confira</Link>
                        </p>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.containerProject}>
                        <div className={styles.leftColumn}>
                            <Image
                                src={require(`assets/images/projects/ProImprensa.png`)}
                                webp={require(`assets/images/projects/ProImprensa.webp`)}
                                alt="Imagem do projeto proimprensa"
                            />
                        </div>

                        <div className={styles.rightColumn}>
                            <Image
                                src={require(`assets/images/projects/ProImprensa2.png`)}
                                webp={require(`assets/images/projects/ProImprensa2.webp`)}
                                alt="Imagem do projeto proimprensa"
                            />

                            <Image
                                src={require(`assets/images/projects/ProImprensa3.png`)}
                                webp={require(`assets/images/projects/ProImprensa3.webp`)}
                                alt="Imagem do projeto proimprensa"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.contentRight}>
                <div className={styles.left}>
                <div className={styles.containerProject}>
                        <div className={styles.leftColumn}>
                            <Image
                                src={require(`assets/images/projects/motorsbr.png`)}
                                webp={require(`assets/images/projects/motorsbr.png`)}
                                alt="Imagem do projeto Motors BR"
                            />
                        </div>

                        <div className={styles.rightColumn}>
                            <Image
                                src={require(`assets/images/projects/motorsbr2.png`)}
                                webp={require(`assets/images/projects/motorsbr2.webp`)}
                                alt="Imagem do projeto Motors BR"
                            />

                            <Image
                                src={require(`assets/images/projects/motorsbr3.png`)}
                                webp={require(`assets/images/projects/motorsbr3.webp`)}
                                alt="Imagem do projeto Motors BR"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <h2>Projeto Motors BR</h2>

                    <div className={styles.backgroundText}>
                        <p>O site apresentado foi desenvolvido utilizando a plataforma Magento 2, uma das soluções de e-commerce mais robustas e completas disponíveis no mercado atualmente. No núcleo do desenvolvimento, foi empregada a arquitetura monolítica, característica do Magento 2, que integra todos os recursos de forma coesa em um único sistema. Esta abordagem permite uma estrutura organizada e centralizada, o que facilita a manutenção e a escalabilidade do site.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>Além disso, toda a gestão de dados é suportada por um banco de dados MySQL, que é amplamente reconhecido pela sua confiabilidade, desempenho e compatibilidade com o Magento. O uso de MySQL no contexto do Magento 2 garante a manipulação eficiente de informações críticas, como gerenciamento de produtos, dados de clientes, histórico de pedidos e relatórios analíticos.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>No frontend, o site aproveita a flexibilidade e o poder do CSS3 para criar um design moderno e responsivo, proporcionado também pelo uso de tecnologias como HTML5 e frameworks JavaScript nativos do Magento 2, como RequireJS e KnockoutJS. O layout foi cuidadosamente organizado para oferecer uma experiência de navegação fluida, atendendo aos padrões contemporâneos de design e funcionalidades.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>Entre as funcionalidades oferecidas no site, destacam-se os sistemas avançados de gerenciamento de produtos e categorias, suporte a múltiplos métodos de pagamento e envio, e a integração com APIs que permitem o monitoramento em tempo real de estoque e logística. Também há suporte para a otimização de SEO, um ponto crucial em plataformas Magento 2, garantindo que o site se destaque nos mecanismos de busca.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>Por fim, a estrutura monolítica do Magento 2 contribuiu diretamente para o desenvolvimento de recursos modulares que se alinham perfeitamente com as necessidades do site. Recursos como módulos para promoções, personalização de produtos, e gestão de campanhas de marketing tornam esta solução robusta e funcional, consolidando o site como uma plataforma de ponta para vendas online no mercado automotivo.</p>
                    </div>

                    <div className={styles.backgroundText}>
                        <p>
                            <Link className={styles.link} to="https://www.motorsbr.com.br" target="_blank">Confira</Link>
                        </p>
                    </div>
                </div>
            </section>
        </>        
    )
}